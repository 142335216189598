"use client";

import { useTranslation } from "react-i18next";
import Translations from "@/app/constants/translations";

export default function NotFoundText() {
    const { t } = useTranslation(Translations.NOT_FOUND);

    return (
        <section className="flex flex-col gap-4 text-center">
            <h1
                className="font-extrabold text-4xl text-neutral-950 
                dark:text-neutral-50 md:text-5xl lg:text-6xl xl:text-7xl"
            >
                {t(`${Translations.NOT_FOUND}:title`)}
            </h1>
            <p className="text-neutral-700 dark:text-neutral-300">
                {t(`${Translations.NOT_FOUND}:description`)}
            </p>
        </section>
    );
}
