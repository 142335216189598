"use client";

import Link from "next/link";
import { useTranslation } from "react-i18next";
import Routes from "@/app/constants/routes";
import Translations from "@/app/constants/translations";

export default function NotFoundButton() {
    const { t } = useTranslation(Translations.NOT_FOUND);

    return (
        <Link
            href={Routes.HOME}
            className="max-w-96 w-full px-6 py-3 rounded-full bg-gradient-to-br 
            from-rose-500 to-rose-600 truncate 
            backdrop-blur-sm shadow-lg text-center lg:px-8 lg:py-3 
            dark:shadow-none hover:shadow-xl hover:scale-105
            transition-all ease-in-out duration-300"
        >
            <span className="text-neutral-50 font-bold">
                {t(`${Translations.NOT_FOUND}:button`)}
            </span>
        </Link>
    );
}
